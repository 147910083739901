import { Controller } from "@hotwired/stimulus";

class OrdersEditExtraSpares extends Controller {
  declare trigger: HTMLElement | null;

  connect() {
    this.trigger = document.getElementById("sheet-trigger");
  }

  toggleSheet() {
    this.trigger?.click();
  }

  closeSheet() {
    document.getElementById("close-sheet")?.click();
  }
}

export default OrdersEditExtraSpares;
