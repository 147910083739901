import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["list"];

  declare listTargets: HTMLElement[];

  connect() {
    const currentUrl = new URL(window.location.href);

    this.listTargets.forEach((linkList) => {
      const links = linkList.querySelectorAll<HTMLAnchorElement>("a");

      links.forEach((link) => {
        const anchorUrl = new URL(link.href);
        const isSamePath =
          currentUrl.pathname.includes(anchorUrl.pathname) &&
          anchorUrl.pathname !== "/";

        link.classList.toggle("!bg-autodeal-yellow", isSamePath);
        link.classList.toggle("!text-dark-blue", isSamePath);
      });
    });
  }
}
