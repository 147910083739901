import { signal } from "@preact/signals";

export enum QuestionType {
  short = "short",
  paragraph = "paragraph",
  multipleChoice = "multiple_choice",
  checkbox = "checkbox",
  dropdown = "dropdown",
  scale = "scale",
  multipleChoiceGrid = "multiple_choice_grid",
  checkboxGrid = "checkbox_grid",
}

export interface Question {
  question?: string;
  type: QuestionType;
  data: QuestionData;
}

export interface QuestionData {
  rows?: string[];
  columns?: string[];
}

interface Section {
  title: string;
  questions: Question[];
}

interface ReportEditorStore {
  supportedQuestions: { type: string; text: string }[];
  questions: Question[];
  sections: Section[];
}

export const store = signal<ReportEditorStore>({
  supportedQuestions: [],
  questions: [],
  sections: [],
});

export const questionsController = {
  add: (question: string, type: QuestionType) => {
    const newQuestion: Question = { question, type, data: {} };
    store.value = {
      ...store.value,
      questions: [...store.value.questions, newQuestion],
    };
  },
  edit: (index: number, changes: Partial<Question>) => {
    store.value = {
      ...store.value,
      questions: store.value.questions.map((q, i) =>
        i === index ? { ...q, ...changes } : q,
      ),
    };
  },
  remove: (index: number) => {
    const updatedQuestions = [...store.value.questions]; // Create a copy of the array
    updatedQuestions.splice(index, 1); // Remove the item at the specified index

    store.value = {
      ...store.value,
      questions: [...updatedQuestions],
    };
  },
  duplicate: (index: number) => {
    const duplicatedQuestion = store.value.questions[index];
    store.value = {
      ...store.value,
      questions: [
        ...store.value.questions.slice(0, index + 1),
        duplicatedQuestion,
        ...store.value.questions.slice(index + 1),
      ],
    };
  },
};

export const optionsController = {
  add: (questionIndex: number, type: "rows" | "columns") => {
    store.value = {
      ...store.value,
      questions: store.value.questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              data: {
                ...question.data,
                [type]: [...(question.data[type] || []), ""],
              },
            }
          : question,
      ),
    };
  },
  edit: (
    questionIndex: number,
    optionIndex: number,
    value: string,
    type: "rows" | "columns",
  ) => {
    store.value = {
      ...store.value,
      questions: store.value.questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              data: {
                ...question.data,
                [type]:
                  question.data[type]?.map((option, j) =>
                    j === optionIndex ? value : option,
                  ) || [],
              },
            }
          : question,
      ),
    };
  },
  remove: (
    questionIndex: number,
    optionIndex: number,
    type: "rows" | "columns",
  ) => {
    store.value = {
      ...store.value,
      questions: store.value.questions.map((question, i) =>
        i === questionIndex
          ? {
              ...question,
              data: {
                ...question.data,
                [type]:
                  question.data[type]?.filter((_, j) => j !== optionIndex) ||
                  [],
              },
            }
          : question,
      ),
    };
  },
};
