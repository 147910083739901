import { type ActionEvent, Controller } from "@hotwired/stimulus";
import { html, render } from "htm/preact/index.js";
import { ItemList } from "@/components/confirmations/show/ItemList.tsx";
import {
  type Labor,
  manageLabors,
  manageSpares,
  store,
} from "@/stores/confirmation-cart-store.ts";

export default class ConfirmationsShowSelectController extends Controller {
  static targets = ["cart", "input"];
  static values = {
    url: String,
    currencySymbol: String,
  };

  declare cartTarget: HTMLElement;
  declare inputTarget: HTMLInputElement;

  declare urlValue: string;
  declare currencySymbolValue: string;

  initialize() {
    render(
      html` <${ItemList} currencySymbol=${this.currencySymbolValue} />`,
      this.cartTarget as HTMLElement,
    );
  }

  public selectLabor({ currentTarget, params }: ActionEvent) {
    this.addOrRemoveLabor(currentTarget as HTMLElement, params.data);
  }

  public selectSpare({ currentTarget, params }: ActionEvent) {
    this.addOrRemoveSpare(currentTarget as HTMLElement, params.data);
  }

  private addOrRemoveLabor(target: HTMLElement, data: Labor) {
    if (Object.keys(store.value.labors).includes(data.id)) {
      manageLabors.remove(data);
      this.onLaborUnselect(target);
      return;
    }
    manageLabors.add(data);
    this.onLaborSelect(target);
  }

  private addOrRemoveSpare(target: HTMLElement, data: any) {
    if (Object.keys(store.value.spares).includes(data.id)) {
      manageSpares.remove(data);
      this.onLaborUnselect(target);
      return;
    }
    manageSpares.add(data);
    this.onLaborSelect(target);
  }

  public submit() {
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify({
        confirmation: {
          labors: Object.keys(store.value.labors),
          spares: Object.keys(store.value.spares),
        },
      }),
    })
      .then((res) => res.json())
      .then(({ fallback }) => {
        if (fallback) {
          window.location.href = fallback;
        }
      });
  }

  public submitNone() {
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')!
          .getAttribute("content") as string,
      },
      body: JSON.stringify({
        confirmation: { action: "deny" },
      }),
    });
  }

  public submitAll() {
    fetch(this.urlValue, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')!
          .getAttribute("content") as string,
      },
      body: JSON.stringify({
        confirmation: { action: "accept" },
      }),
    });
  }

  private onLaborSelect(target: HTMLElement) {
    target.classList.add("!border-emerald-400", "hover:!border-emerald-200");
  }

  private onLaborUnselect(target: HTMLElement) {
    target.classList.remove("!border-emerald-400", "hover:!border-emerald-200");
  }
}
