import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    position: Array,
  };

  declare positionValue: [string, string];

  connect() {

  }
}
