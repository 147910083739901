import { Controller } from "@hotwired/stimulus";
import html2pdf from 'html2pdf.js';

export default class PdfController extends Controller {
    static targets = ["pdf"];

    connect() {
        console.log("Connected to PDF controller");
    }

    trigger() {
        console.log(this.element)
        html2pdf(this.element)
    }
}