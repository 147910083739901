import type { Key } from "preact";
import { type Spare } from "@/stores/confirmation-cart-store.ts";

interface SpareProps extends Omit<Spare, "key"> {
  key?: Key; // React.Key is either string | number
}

export function Spare({ key, name, price }: SpareProps) {
  return (
    <li key={key}>
      <div className="flex my-0.5">
        <span className="flex-1 pr-10 text-sm truncate block overflow-hidden whitespace-nowrap">
          {name}
        </span>
        <span className="text-sm">{price}</span>
      </div>
    </li>
  );
}
