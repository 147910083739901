import { type ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "job"];

  declare formTarget: HTMLFormElement;
  declare jobTargets: HTMLInputElement[];
  declare jobCountValue: number;
  declare urlValue: string;
  declare extraJobs: HTMLInputElement[];

  static values = {
    jobCount: Number,
    url: String,
  };

  connect() {
    this.extraJobs = Array.from(this.jobTargets)
      .slice(this.jobCountValue)
      .map((element) => element.cloneNode(true)) as HTMLInputElement[];
  }

  async headerSelect({ currentTarget }: ActionEvent) {
    try {
      const response = await this.fetchPackage(
        (currentTarget as HTMLSelectElement).value,
      );
      const { price, jobs } = await response.json();

      this.setPackageJobs(jobs);
      this.setPrice(price);
    } catch (error) {
      console.error(error);
    }
  }

  async fetchPackage(id: string): Promise<Response> {
    return await fetch(`${this.urlValue}/${id}`, {
      headers: {
        Accept: "application/json",
      },
    });
  }

  clearJobs() {
    this.jobTargets.forEach((job) => (job.value = ""));
  }

  setPackageJobs(jobs: string[]) {
    this.clearJobs();

    this.jobTargets.forEach((input, index) => {
      if (jobs[index] === undefined) return;

      input.value = jobs[index];
      input.removeAttribute("name");
    });
    this.jobCountValue = jobs.length;

    this.arrangeJobs();
  }

  /**
   * When package is selected, extra jobs are memorized and inserted into the form after the fetched packages
   */
  arrangeJobs() {
    this.jobTargets.forEach((input, index) => {
      if (this.jobCountValue <= index) {
        input.name = "work_order[extra_jobs][]";
        input.disabled = false;
        input.classList.remove("bg-slate-200");

        if (this.extraJobs[index - this.jobCountValue])
          input.value = this.extraJobs[index - this.jobCountValue].value;
      } else {
        input.disabled = true;
        input.classList.add("bg-slate-200");
      }
    });
  }

  setPrice(price: number) {
    const input = document.getElementById(
      "work_order_estimated_cost",
    ) as HTMLInputElement;
    input.value = price.toString();
  }
}
