import { Controller } from "@hotwired/stimulus";
import {
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
} from "@floating-ui/dom";
import anime from "animejs";

export default class extends Controller {
  static targets = ["trigger", "menu"];

  declare triggerTarget: HTMLButtonElement;
  declare menuTarget: HTMLElement;

  visible = false;

  connect() {
    document.addEventListener("click", this.handleClickOutside.bind(this));

    autoUpdate(this.triggerTarget, this.menuTarget, () => {
      if (this.visible)
        computePosition(this.triggerTarget, this.menuTarget, {
          placement: "bottom",
          middleware: [shift({ padding: 5 }), flip(), offset(10)],
        }).then(({ x, y }) => {
          Object.assign(this.menuTarget.style, {
            left: `${x}px`,
            top: `${y}px`,
          });
        });
    });
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  public showTooltip() {
    this.menuTarget.classList.remove("hidden");

    if (!this.visible) {
      anime({
        targets: this.menuTarget,
        easing: "easeOutExpo",
        scale: [0.95, 1],
        opacity: [0, 1],
        duration: 200,
      });
      this.visible = true;
    }
  }

  public hideTooltip() {
    if (this.visible) {
      anime({
        targets: this.menuTarget,
        easing: "easeInExpo",
        scale: [1, 0.95],
        opacity: [1, 0],
        duration: 200,
        complete: () => {
          this.menuTarget.classList.add("hidden");
          this.visible = false;
        },
      });
    }
  }

  private handleClickOutside(event: Event) {
    if (
      this.element &&
      !this.element.contains(event.target as Node) &&
      this.menuTarget &&
      !this.menuTarget.contains(event.target as Node)
    ) {
      this.hideTooltip();
    }
  }
}
