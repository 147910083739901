import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "previousImageButton",
    "removeLastIconButton",
    "nextImageButton",
    "saveImagesButton",
  ];

  declare previousImageButtonTarget: HTMLButtonElement;
  declare removeLastIconButtonTarget: HTMLButtonElement;
  declare nextImageButtonTarget: HTMLButtonElement;
  declare saveImagesButtonTarget: HTMLButtonElement;
  declare xImageUrlValue: string;
  declare urlValue: string;
  declare fallbackUrlValue: string;

  static values = {
    url: String,
    xImageUrl: String,
    fallbackUrl: String,
  };

  images = ["frontImage", "leftImage", "upImage", "rightImage", "backImage"];
  payload = {
    frontImage: [],
    leftImage: [],
    upImage: [],
    rightImage: [],
    backImage: [],
  };

  public insertIcon(event: MouseEvent) {
    const target = event.target as HTMLElement;

    const width = target.offsetWidth;
    const height = target.offsetHeight;
    const xPosition = event.clientX - target.parentElement.offsetLeft;
    const yPosition = event.clientY - target.parentElement.offsetTop;
    this.insertXIconAt(xPosition, yPosition, target.parentElement);
    this.payload[this.element.id].push({
      x: this.positionToPercentage(xPosition, width),
      y: this.positionToPercentage(yPosition, height),
    });
    this.enableButton(this.removeLastIconButtonTarget, "button-danger");
  }

  insertXIconAt(x: number, y: number, element: HTMLElement) {
    const xIcon = document.createElement("img");
    xIcon.src = this.xImageUrlValue;
    xIcon.classList.add("pointer-events-none", "select-none");
    xIcon.style.position = "absolute";
    xIcon.style.left = `${x - 15}px`;
    xIcon.style.top = `${y - 15}px`;
    element.appendChild(xIcon);
  }

  positionToPercentage(position: number, total: number) {
    return (position / total).toFixed(2);
  }

  enableButton(button: HTMLButtonElement, className: string) {
    button.classList.remove("button-disabled");
    button.classList.add(className);
  }

  disableButton(button: HTMLButtonElement, className: string) {
    button.classList.remove(className);
    button.classList.add("button-disabled");
  }

  removeLastIcon() {
    const currentImage = this.element.id;
    const element = document.getElementById(`${currentImage}Container`);
    this.payload[this.element.id].pop();
    element.lastChild.remove();
    if (element.childElementCount === 1)
      this.disableButton(this.removeLastIconButtonTarget, "button-danger");
  }

  nextImage() {
    const currentImage = this.element.id;
    const nextImage = this.images[this.images.indexOf(currentImage) + 1];
    this.element.id = nextImage;
    document.getElementById(`${currentImage}Container`).classList.add("hidden");
    document.getElementById(`${nextImage}Container`).classList.remove("hidden");
    if (currentImage === "frontImage")
      this.enableButton(this.previousImageButtonTarget, "button-primary");
    if (nextImage === "backImage") {
      this.saveImagesButtonTarget.classList.remove("hidden");
      this.nextImageButtonTarget.classList.add("hidden");
    }
    this.toggleRemoveLastIconButton();
  }

  previousImage() {
    const currentImage = this.element.id;
    const previousImage = this.images[this.images.indexOf(currentImage) - 1];
    this.element.id = previousImage;
    document.getElementById(`${currentImage}Container`).classList.add("hidden");
    document
      .getElementById(`${previousImage}Container`)
      .classList.remove("hidden");
    if (currentImage === "leftImage")
      this.disableButton(this.previousImageButtonTarget, "button-primary");
    if (currentImage === "backImage") {
      this.nextImageButtonTarget.classList.remove("hidden");
      this.saveImagesButtonTarget.classList.add("hidden");
    }
    this.toggleRemoveLastIconButton();
  }

  toggleRemoveLastIconButton() {
    const currentImage = this.element.id;
    const element = document.getElementById(`${currentImage}Container`);
    if (element.childElementCount > 1) {
      this.enableButton(this.removeLastIconButtonTarget, "button-danger");
    } else {
      this.disableButton(this.removeLastIconButtonTarget, "button-danger");
    }
  }

  async saveImages() {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const payload = {
      front: this.payload.frontImage,
      left: this.payload.leftImage,
      up: this.payload.upImage,
      right: this.payload.rightImage,
      back: this.payload.backImage,
    };

    await fetch(this.urlValue, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
        dataType: "script",
      },
      body: JSON.stringify({ damages: payload }),
    }).then(() => {
      window.location.href = this.fallbackUrlValue;
    });
  }
}
