import type { Key } from "preact";
import { type Labor } from "@/stores/confirmation-cart-store.ts";
import { useEffect, useRef } from "preact/hooks";
import anime from "animejs";
import { formatCurrency } from "@/util/formatCurrency.ts";

interface LaborProps extends Partial<Labor> {
  key?: Key; // React.Key is either string | number
  currencySymbol: string;
}

export function Labor({
  key,
  description,
  price,
  spares,
  currencySymbol,
}: LaborProps) {
  const animationRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    anime({
      targets: animationRef.current,
      opacity: [0, 1],
      translateX: [10, 0],
      duration: 300,
      easing: "easeOutQuad",
    });
  }, []);

  return (
    <li
      ref={animationRef}
      key={key}
      className={`${spares.length > 0 ? "border-b my-2" : ""}`}
    >
      <div className="flex my-0.5">
        <span className="flex-1 pr-10 text-sm truncate block overflow-hidden whitespace-nowrap">
          {description}
        </span>
        <span className="text-sm font-bold">
          {formatCurrency(currencySymbol, price)}
        </span>
      </div>
      {spares.length > 0 && (
        <ul className="ml-3 list-disc pb-2">
          {spares.map((spare) => (
            <li key={spare.id} className="flex my-0.5">
              <span className="flex-1 pr-10  truncate block overflow-hidden whitespace-nowrap text-xs">
                {spare.name}
              </span>
              <span className="text-xs">
                {formatCurrency(currencySymbol, spare.price)}
              </span>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}
