import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle({ currentTarget }: MouseEvent) {
    const target = currentTarget as HTMLElement;
    const panel = target.nextElementSibling as HTMLElement;
    target.classList.toggle("bg-stone-50");

    if (panel.style.maxHeight) {
      panel.style.maxHeight = "";
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
}
