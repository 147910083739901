import {
  optionsController,
  type QuestionData,
  QuestionType,
} from "@/stores/report-editor-store.js";

interface CheckBoxProps {
  index: number;
  data: QuestionData;
  type: QuestionType;
}

export function MultipleGrid({ index, data, type }: CheckBoxProps) {
  const handleOptionAdd = (type: "rows" | "columns") =>
    optionsController.add(index, type);
  const handleOptionChange = (
    optionIndex: number,
    value: string,
    type: "rows" | "columns",
  ) => optionsController.edit(index, optionIndex, value, type);
  const handleOptionDelete = (optionIndex: number, type: "rows" | "columns") =>
    optionsController.remove(index, optionIndex, type);

  const iconMap = {
    [QuestionType.multipleChoiceGrid]: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-square"
      >
        <rect width="18" height="18" x="3" y="3" rx="2" />
      </svg>
    ),
    [QuestionType.checkboxGrid]: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-circle"
      >
        <circle cx="12" cy="12" r="10" />
      </svg>
    ),
  };

  // Function to render options (rows or columns)
  const renderOptions = (
    options: string[],
    label: string,
    dataType: "rows" | "columns",
  ) =>
    options.map((option, i) => (
      <div key={i} className="flex items-center">
        {type === QuestionType.dropdown ? (
          <span className="text-lg">{i + 1}.</span>
        ) : (
          iconMap[type]
        )}
        <input
          type="text"
          className="w-full ml-2 border-0 border-b border-transparent focus:ring-0 focus:border-b-2 focus:border-gray-900 transition duration-300"
          placeholder={`${label} ${i + 1}`}
          value={option ?? `${label} ${i + 1}`}
          onChange={(event) =>
            handleOptionChange(
              i,
              (event.target as HTMLInputElement).value,
              dataType,
            )
          }
        />
        {i !== 0 && (
          <button
            onClick={() => handleOptionDelete(i, dataType)}
            className="focus:bg-gray-100 scale-95 rounded-full p-1 transition duration-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
        )}
      </div>
    ));

  return (
    <div className="flex gap-x-3">
      <div className="w-1/2 pb-2">
        <span className="text-sm">
          {data.rows && renderOptions(data.rows, "Opción", "rows")}
          <div>
            <button
              onClick={() => handleOptionAdd("rows")}
              className="text-sm text-gray-500 mt-2 py-2"
            >
              Agregar opción
            </button>
          </div>
        </span>
      </div>
      <div className="w-1/2 pb-2">
        <span className="text-sm">
          {data.columns && renderOptions(data.columns, "Opción", "columns")}
          <div>
            <button
              onClick={() => handleOptionAdd("columns")}
              className="text-sm text-gray-500 mt-2 py-2"
            >
              Agregar opción
            </button>
          </div>
        </span>
      </div>
    </div>
  );
}
