import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  declare containerTarget: HTMLElement;

  open() {
    this.containerTarget.classList.remove("hidden");
  }
  close() {
    this.containerTarget.classList.add("hidden");
  }
}
