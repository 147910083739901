import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import {
  store,
  type Labor as LaborType,
  type Spare as SpareType,
} from "@/stores/confirmation-cart-store.ts";
import { Labor } from "@/components/confirmations/show/Labor.tsx";
import { Spare } from "@/components/confirmations/show/Spare.tsx";

export function ItemList({ currencySymbol }) {
  const [labors, setLabors] = useState<{ [key: string]: LaborType }>({});
  const [spares, setSpares] = useState<{ [key: string]: SpareType }>({});

  const total = useMemo(() => {
    const laborTotal = Object.values(labors).reduce(
      (sum, labor) => sum + labor.total,
      0,
    );
    const spareTotal = Object.values(spares).reduce(
      (sum, spare) => sum + spare.price,
      0,
    );
    return laborTotal + spareTotal;
  }, [labors, spares]);

  useEffect(() => {
    store.subscribe((state) => {
      setLabors(state.labors);
      setSpares(state.spares);
    });
  }, []);

  return (
    <ul className="rounded-lg border bg-card text-card-foreground shadow-sm p-5">
      {Object.keys(labors).map((item, key) => (
        <Labor
          key={key}
          description={labors[item].description}
          price={labors[item].price}
          spares={labors[item].spares}
          currencySymbol={currencySymbol}
        />
      ))}
      {Object.keys(spares).map((item, key) => (
        <Spare key={key} name={spares[item].name} price={spares[item].price} />
      ))}
      {Object.keys(labors).length > 0 || Object.keys(spares).length > 0 ? (
        <>
          <li>
            <div className="flex my-0.5">
              <span className="flex-1 pr-10 text-sm truncate block overflow-hidden whitespace-nowrap font-bold">
                Total
              </span>
              <div>
                <span className="text-sm font-bold mr-4">{currencySymbol}</span>
                <span>{total.toFixed(2)}</span>
              </div>
            </div>
          </li>
        </>
      ) : null}
    </ul>
  );
}
