import { Controller } from "@hotwired/stimulus";
import anime from "animejs";

export default class extends Controller {
  static targets = ["backdrop", "dialog"];

  declare backdropTarget: HTMLElement;
  declare dialogTarget: HTMLElement;

  open() {
    this.backdropTarget.classList.remove("hidden");
    this.dialogTarget.classList.remove("hidden");

    anime({
      targets: this.backdropTarget,
      easing: "easeOutExpo",
      opacity: [0, 1],
      duration: 300,
    });

    anime({
      targets: this.dialogTarget,
      easing: "easeOutExpo",
      opacity: [0, 1],
      duration: 300, // Adjusted duration for smoother animation
    });
  }

  close() {
    anime({
      targets: this.backdropTarget,
      easing: "easeInExpo",
      opacity: [1, 0],
      duration: 300,
    });

    anime({
      targets: this.dialogTarget,
      easing: "easeInExpo",
      opacity: [1, 0],
      duration: 300,
    });

    setTimeout(() => {
      this.backdropTarget.classList.add("hidden");
      this.dialogTarget.classList.add("hidden");
    }, 350); // Slightly longer timeout to allow animations to complete
  }

  backdropClose(e: MouseEvent) {
    if (e.currentTarget !== e.target) return; // Ensures the click is on the backdrop, not a child element
    this.close();
  }
}