import { Controller } from "@hotwired/stimulus";

export default class FiltersController extends Controller {
  declare url: URL;
  declare targetFrame: string;
  declare headers: Headers;

  connect() {
    this.url = new URL(document.URL);

    this.targetFrame = "";

    this.headers = new Headers();

    this.headers.append("Accept", "text/vnd.turbo-stream.html");

    document.addEventListener(
      "turbo:before-stream-render",
      async (event: CustomEvent) => {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (document.startViewTransition) {
          await document.startViewTransition(() => {
            event.detail.render(event.detail.newStream);
          });
        } else {
          event.detail.render(event.detail.newStream);
        }
      },
    );
  }

  async setSearchParameterAndVisit(param: string, value: string) {
    this.url.searchParams.set(param, value);

    fetch(this.url, {
      headers: this.headers,
    })
      .then((response) => response.text())
      .then((html) => window.Turbo.renderStreamMessage(html));
  }
}
