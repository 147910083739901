import { useCallback, useEffect, useState } from "preact/hooks";
import {
  type Question as TQuestion,
  questionsController,
  QuestionType,
  store,
} from "@/stores/report-editor-store.ts";
import { Question } from "@/components/reports/Question.tsx";
import debounce from "@/util/debounce.js";

interface FormProps {
  title: string;
  description: string;
  url: string;
}

export function Form(props: FormProps) {
  const [questions, setQuestions] = useState<TQuestion[]>([]);
  const [title, setTitle] = useState(props.title);
  const [description, setDescription] = useState(props.description);

  // Debounced submission to avoid excessive network requests
  const debouncedSubmit = useCallback(
    debounce(() => {
      fetch(props.url, {
        method: "PATCH",
        body: JSON.stringify({
          report: {
            title,
            description,
            data: {
              questions: store.value.questions,
            },
          },
        }),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector("meta[name='csrf-token']")
            .getAttribute("content"),
        },
      });
    }, 3500),
    [title, description, questions], // Dependencies: re-run if title, description, or questions change
  );

  // Add new question to the store
  const handleQuestionAdd = () => {
    questionsController.add("", QuestionType.short);
  };

  // Update title and trigger debounced submission
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    debouncedSubmit();
  };

  // Update description and trigger debounced submission
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    debouncedSubmit();
  };

  // Subscribe to store changes to update the questions state
  useEffect(() => {
    const unsubscribe = store.subscribe((state) => {
      setQuestions(state.questions);
      debouncedSubmit();
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [debouncedSubmit]);

  return (
    <>
      <div className="w-full md:w-11/12 lg:w-3/4 2xl:w-1/2">
        <div className="rounded-lg border bg-card text-card-foreground shadow-sm my-4">
          <div className="w-full h-3 bg-foreground rounded-t-md mb-2"></div>
          <div className="flex flex-col">
            <input
              value={title}
              onChange={handleTitleChange}
              placeholder="Formulario sin título"
              className="w-full mb-1 border-0 border-b border-transparent focus:ring-0 focus:border-b-2 focus:border-gray-900 transition duration-300 text-2xl"
            />
            <input
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Descripción del reporte"
              className="w-full border-0 mb-2 border-b border-transparent focus:ring-0 focus:border-b-2 focus:border-gray-900 transition duration-300"
            />
          </div>
        </div>

        {/* Render questions */}
        {questions.map(({ question, type, data }, index) => (
          <Question
            key={index} // Use a proper key, like `question.id` if available
            index={index}
            question={question}
            data={data}
            type={type}
          />
        ))}
      </div>

      {/* Add Question Button */}
      <div className="border-t border-gray-200 fixed bottom-0 w-full xl:w-[calc(100vw_-_18rem)] bg-white/50 dark:bg-black/60 backdrop-blur z-30 px-2 sm:px-10 py-2 flex justify-between">
        <button
          onClick={handleQuestionAdd}
          className="bg-transparent text-black p-3 rounded-full hover:bg-muted transition duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-circle-plus"
          >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 12h8" />
            <path d="M12 8v8" />
          </svg>
        </button>
      </div>
    </>
  );
}
