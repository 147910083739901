import { Controller } from "@hotwired/stimulus";
import { html, render } from "htm/preact/index.js";
import { Form } from "@/components/reports/Form.js";
import { type Question, store } from "@/stores/report-editor-store.js";

export default class ManagerReportsEditFormController extends Controller {
  static targets = ["container"];

  declare containerTarget: HTMLElement;

  static values = {
    supportedTypes: Array,
    payload: Object,
    url: String,
  };
  declare supportedTypesValue: { text: string; type: string }[];
  declare payloadValue: {
    title: string;
    description: string;
    active: boolean;
    data: { questions: Question[] };
  };
  declare urlValue: string;

  initialize() {
    store.value.supportedQuestions = this.supportedTypesValue;
    store.value.questions = this.payloadValue.data.questions ?? [];
    render(
      html`<${Form}
        title="${this.payloadValue.title}"
        description="${this.payloadValue.description}"
        url="${this.urlValue}"
      />`,
      this.containerTarget,
    );
  }
}
