import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectedVehicle", "unselectedVehicle", "form"];

  declare selectedVehicleTarget: HTMLDivElement;
  declare unselectedVehicleTarget: HTMLDivElement;
  declare formTarget: HTMLFormElement;

  declare vehicleField: HTMLInputElement;
  declare clientField: HTMLInputElement;

  connect() {
    this.vehicleField = document.getElementById(
      "work_order_vehicle_id",
    ) as HTMLInputElement;
    this.clientField = document.getElementById(
      "work_order_client_id",
    ) as HTMLInputElement;

    document.addEventListener("autodeal:vehicle-selected", () => {
      this.updateVehicle();
      setTimeout(() => {
        this.setVehicleFormValues();
      }, 1000);
    });
  }

  /**
   * Triggers on a turbo frame load which will update hidden fields on the vehicle form
   * The data comes from the frame on a hidden field
   */
  setVehicleFormValues() {
    this.vehicleField.value =
      document.getElementById("vehicle_id")?.innerText || "";
    this.clientField.value =
      document.getElementById("vehicle_owner_id")?.innerText || "";
  }

  unsetVehicleFormValues() {
    this.vehicleField.value = "";
    this.clientField.value = "";
  }

  updateVehicle() {
    this.selectedVehicleTarget.classList.remove("hidden");
    this.unselectedVehicleTarget.classList.add("hidden");
  }

  unselect() {
    this.selectedVehicleTarget.classList.add("hidden");
    this.unselectedVehicleTarget.classList.remove("hidden");

    this.unsetVehicleFormValues();
  }
}
