import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "pane"];

  static values = {
    activeStyle: String,
  };

  declare tabTargets: HTMLButtonElement[];
  declare paneTargets: HTMLElement[];
  declare activeStyleValue: string;

  connect() {
    this.showTab(0);
  }

  public open({ currentTarget }: Event) {
    this.showTab(this.tabTargets.indexOf(currentTarget as HTMLButtonElement));
  }

  public openSelect(event: any) {
    this.showTab(event.currentTarget.selectedIndex);
  }

  private showTab(index: number) {
    this.tabTargets.forEach((tab, i) => {
      if (index === i) this.setActiveStyles(tab);
      else this.removeActiveStyles(tab);
    });

    this.paneTargets.forEach((pane, i) => {
      pane.classList.toggle("hidden", index !== i);
      pane.classList.add("animate-opacity-in");
    });
  }

  private setActiveStyles(tab: HTMLButtonElement) {
    if (this.activeStyleValue === "") return;
    this.activeStyleValue.split(" ").forEach((style) => {
      tab.classList.add(style);
    });
  }

  removeActiveStyles(tab: HTMLButtonElement) {
    if (this.activeStyleValue === "") return;
    this.activeStyleValue.split(" ").forEach((style) => {
      tab.classList.remove(style);
    });
  }
}
