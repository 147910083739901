import { Controller } from "@hotwired/stimulus";
import anime from "animejs";

export default class extends Controller {
  static targets = ["container", "backdrop", "sheet"];

  declare containerTarget: HTMLElement;
  declare backdropTarget: HTMLElement;
  declare sheetTarget: HTMLElement;

  connect() {
    document.addEventListener("keydown", (e) => this.escClose(e));
  }

  disconnect() {
    document.removeEventListener("keydown", this.escClose);
  }

  open() {
    this.containerTarget.classList.remove("hidden");
    anime({
      targets: this.backdropTarget,
      easing: "linear",
      opacity: [0, 1],
      duration: 300,
    });
    anime({
      targets: this.sheetTarget,
      translateX: [400, 0],
      duration: 300,
      easing: "easeInOutQuad",
    });
  }

  close() {
    anime({
      targets: this.backdropTarget,
      easing: "linear",
      opacity: [1, 0],
      duration: 300,
    });
    anime({
      targets: this.sheetTarget,
      translateX: [0, 500],
      duration: 300,
      easing: "easeInOutQuad",
    });
    setTimeout(() => {
      this.containerTarget.classList.add("hidden");
    }, 450);
  }

  backdropClose(e: MouseEvent) {
    if (e.currentTarget !== e.target) return;
    this.close();
  }

  escClose(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.close();
    }
  }
}
