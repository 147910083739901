import { useEffect, useState } from "preact/hooks";
import {
  type QuestionData,
  questionsController,
  QuestionType,
  store,
} from "@/stores/report-editor-store.js";
import { Multiple } from "@/components/reports/questions/Multiple.js";
import { Text } from "@/components/reports/questions/Text.js";
import { Scale } from "@/components/reports/questions/Scale.js";
import { MultipleGrid } from "@/components/reports/questions/MultipleGrid.js";

export interface QuestionProps {
  index: number;
  question?: string | undefined;
  type: QuestionType;
  data: QuestionData;
}

export function Question({ index, question, type, data }: QuestionProps) {
  const [questionTitle, setQuestionTitle] = useState<string>(question);
  const [questionType, setQuestionType] = useState<QuestionType>(type);

  useEffect(() => {
    setQuestionTitle(question);
    setQuestionType(type);
  }, [question, type]);

  const handleTypeChange = (event: any) => {
    const newType = event.target.value;

    setQuestionType(event.target.value);
    questionsController.edit(index, {
      question: questionTitle,
      type: newType,
      data,
    });
  };

  const handleTitleChange = (event) => {
    setQuestionTitle(event.target.value);
    questionsController.edit(index, {
      question: event.target.value,
      type: questionType,
      data,
    });
  };

  const handleDuplicationClick = (index) => {
    questionsController.duplicate(index);
  };

  const handleDeleteClick = (index) => {
    questionsController.remove(index);
  };

  const componentMap = {
    short: <Text type={type} />, // Example component for 'short'
    paragraph: <Text type={type} />, // Example component for 'paragraph'
    checkbox: (
      <Multiple index={index} data={data} type={QuestionType.checkbox} />
    ), // Example component for 'checkbox'
    multiple_choice: (
      <Multiple index={index} data={data} type={QuestionType.multipleChoice} />
    ),
    dropdown: (
      <Multiple index={index} data={data} type={QuestionType.dropdown} />
    ),
    scale: <Scale index={index} data={data} type={QuestionType.scale} />, // Example component for 'scale'
    multiple_choice_grid: (
      <MultipleGrid
        index={index}
        data={data}
        type={QuestionType.multipleChoiceGrid}
      />
    ),
    checkbox_grid: (
      <MultipleGrid
        index={index}
        data={data}
        type={QuestionType.checkboxGrid}
      />
    ),
  };

  return (
    <div class="rounded-lg border bg-card text-card-foreground shadow-sm my-4">
      <div class="p-6 pb-0">
        <div class="flex flex-col md:flex-row gap-3">
          <input
            onChange={handleTitleChange}
            value={questionTitle}
            placeholder="Pregunta sin título"
            class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          />
          <select
            onChange={handleTypeChange}
            class="flex h-10 items-center justify-between rounded-md border border-input bg-background px-3 pr-10 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
          >
            {store.value.supportedQuestions.map((question) => {
              return (
                <option selected={type === question.type} value={question.type}>
                  {question.text}
                </option>
              );
            })}
          </select>
        </div>
        <div class="py-5">{componentMap[questionType]}</div>
      </div>
      <div class="border-t mx-4 py-3">
        <div class="flex justify-end">
          <button
            onClick={() => handleDuplicationClick(index)}
            className="hover:bg-muted p-2 rounded-full mr-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-copy"
            >
              <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
              <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
            </svg>
          </button>
          <button
            onClick={() => handleDeleteClick(index)}
            className="hover:bg-muted p-2 rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="lucide lucide-trash-2"
            >
              <path d="M3 6h18" />
              <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
              <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
              <line x1="10" x2="10" y1="11" y2="17" />
              <line x1="14" x2="14" y1="11" y2="17" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
