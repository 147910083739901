import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["jobs", "emptyJob"];

  declare jobsTarget: HTMLDivElement;
  declare emptyJobTarget: HTMLDivElement;

  declare jobId: number;

  connect() {
    this.jobId = 0;
    this.add();
  }

  add() {
    const content = this.jobComponent();

    this.jobsTarget.appendChild(content);

    this.jobId++;
  }

  remove(event: Event) {
    const currentTarget = event.currentTarget as HTMLElement;

    if (currentTarget && currentTarget.parentElement) {
      this.jobsTarget.removeChild(currentTarget.parentElement);
    }
  }

  jobComponent() {
    const element = this.emptyJobTarget.cloneNode(true) as HTMLDivElement;
    const button = element.querySelector("button") as HTMLButtonElement;

    if (this.jobId === 0) {
      button.remove();
    }

    (element.querySelector("input") as HTMLInputElement).id =
      `package_jobs_${this.jobId}`;

    element.removeAttribute("data-packages--new--form-target");

    button.setAttribute("data-action", "packages--new--form#remove");

    return element;
  }
}
